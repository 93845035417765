<template>
  <div>
    <div class="d-flex flex-wrap border-bottom">
      <div class="mt-1 mr-3">
        <h4>New Company</h4>
      </div>
    </div>
    <div class="mt-4">
      <a-form
        ref="formRef"
        :rules="rules"
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        @finish="handleFinish"
        label-align="left"
        :colon="false"
      >
        <div class="card card-top">
          <div class="card-body">
            <a-row>
              <a-col :md="12">
                <a-form-item
                  label="Company Name"
                  name="companyName"
                  :wrapper-col="{ span: 14 }"
                  :label-col="{ span: 6 }"
                >
                  <a-input
                    v-model:value="formState.companyName"
                    placeholder="Enter Company Name"
                    allow-clear
                  />
                </a-form-item>
                <a-form-item
                  :wrapper-col="{ span: 14 }"
                  :label-col="{ span: 6 }"
                  label="Branch Name"
                  name="branchName"
                >
                  <a-input
                    v-model:value="formState.branchName"
                    placeholder="Enter Branch Name"
                    allow-clear
                  />
                </a-form-item>
                <a-form-item
                  :wrapper-col="{ span: 14 }"
                  :label-col="{ span: 6 }"
                  label="GSTIN"
                  name="gstin"
                >
                  <a-input v-model:value="formState.gstin" placeholder="Enter GSTIN" allow-clear />
                </a-form-item>
                <a-form-item
                  :wrapper-col="{ span: 14 }"
                  :label-col="{ span: 6 }"
                  label="Invoice Prefix"
                  name="invoicePrefix"
                >
                  <a-input
                    v-model:value="formState.invoicePrefix"
                    placeholder="Enter Invoice Prefix"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="12">
                <a-form-item
                  :wrapper-col="{ span: 14 }"
                  :label-col="{ span: 6 }"
                  label="PAN"
                  name="pan"
                >
                  <a-input v-model:value="formState.pan" placeholder="Enter PAN" allow-clear />
                </a-form-item>
                <a-form-item
                  :wrapper-col="{ span: 14 }"
                  :label-col="{ span: 6 }"
                  label="Email"
                  name="email"
                >
                  <a-input v-model:value="formState.email" placeholder="Enter Email" allow-clear />
                </a-form-item>
                <a-form-item
                  :wrapper-col="{ span: 14 }"
                  :label-col="{ span: 6 }"
                  label="Phone"
                  name="phone"
                >
                  <a-input v-model:value="formState.phone" placeholder="Enter Phone" allow-clear />
                </a-form-item>
              </a-col>
            </a-row>
            <!-- <a-divider /> -->
            <div class="mb-2">
              <a-tabs v-model:active-key="activeTabKey" tab-position="top">
                <a-tab-pane key="1">
                  <template #tab>
                    <a class="nav-link" href="javascript: void(0);">Branch Address</a>
                  </template>
                </a-tab-pane>
                <a-tab-pane key="2">
                  <template #tab>
                    <a class="nav-link" href="javascript: void(0);">Company Bank Accounts</a>
                  </template>
                </a-tab-pane>
              </a-tabs>
            </div>
            <div>
              <div v-if="activeTabKey === '1'">
                <div>
                  <InputFormAddress :address="formState.branchAddress" />
                </div>
              </div>
              <div v-if="activeTabKey === '2'">
                <div>
                  <a-button class="mb-4" @click="isBankAccountModalVisible = true">
                    Add Bank Account
                  </a-button>
                  <ModalSlot
                    v-model:modal-visible="isBankAccountModalVisible"
                    modal-title="Add Bank Account"
                  >
                    <bank-account-input-form @onAddBankAccount="handleAddBankAccount" />
                  </ModalSlot>
                </div>
                <div>
                  <BankAccountAddTable
                    :data-source-prop="formState.bankAccounts || []"
                    @onRemoveBankAccount="handleRemoveBankAccount"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a-button html-type="submit" :loading="isLoading" class="btn btn-primary px-5 mr-3">
            Save
          </a-button>
          <a-button class="btn btn-light px-5  mr-3" @click.prevent="$router.go(-1)">
            Cancel
          </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, toRaw } from 'vue'
import InputFormAddress from '@/components/InputForms/InputFormAddress.vue'
import BankAccountInputForm from '@/components/InputForms/BankAccountInputForm.vue'
import ModalSlot from '@/components/General/ModalSlot.vue'
import { ICompanyBankAccount } from '@/types/interfaces/ICompanyBankAccount'
import { CompanyFormData } from '@/types/appcontracts/CompanyFormData'
import { IAddressFormat } from '@/types/interfaces/IAddressFormat'
import BankAccountAddTable from './BankAccountsAddTable.vue'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { useStore } from 'vuex'
import * as actions from '../../../../store/actions.type'
import { isGstValid } from '@/services/helpers'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 3 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 7 },
}

const initialAddress: IAddressFormat = {
  country: 'India',
  attention: null,
  city: null,
  state: null,
  address: null,
  pincode: null,
}

const getInitialState = () => {
  const initialState: CompanyFormData = {
    companyName: null,
    branchName: null,
    gstin: null,
    invoicePrefix: null,
    branchAddress: { ...initialAddress },
    bankAccounts: [],
    email: null,
    phone: null,
    pan: null,
    photoUrl: null,
  }

  return initialState
}

export default defineComponent({
  components: {
    InputFormAddress,
    BankAccountInputForm,
    ModalSlot,
    BankAccountAddTable,
  },
  setup() {
    const store = useStore()
    const formRef = ref()
    const formState = reactive(getInitialState())

    const validateGstin = async (rule: unknown, value: string) => {
      return isGstValid(value) ? Promise.resolve(true) : Promise.reject('Please enter valid GSTIN')
    }

    // TODO: Add validator for gstin ?
    const rules = {
      companyName: [
        { required: true, message: 'Please enter Company Name', trigger: 'blur', type: 'string' },
      ],
      branchName: [
        { required: true, message: 'Please enter Branch name', trigger: 'blur', type: 'string' },
      ],
      gstin: [
        { required: true, message: 'Please enter GSTIN', trigger: 'blur', type: 'string' },
        { validator: validateGstin, trigger: 'blur' },
      ],
      invoicePrefix: [
        { required: true, message: 'Please enter Invoice Prefix', trigger: 'blur', type: 'string' },
      ],
    }
    const isLoading = ref<boolean>(false)
    const activeTabKey = ref('1')
    const isBankAccountModalVisible = ref<boolean>(false)

    const handleAddBankAccount = (bankAccount: ICompanyBankAccount | null) => {
      if (bankAccount) {
        formState.bankAccounts.push(bankAccount)
      }
      isBankAccountModalVisible.value = false
    }

    const handleRemoveBankAccount = (idx: number) => {
      formState.bankAccounts.splice(idx, 1)
    }

    const handleFinish = async () => {
      isLoading.value = true
      const docOperation: DocumentOperation<CompanyFormData> = {
        action: DocumentActions.CREATE,
        id: null,
        payload: toRaw(formState),
        audit: null,
      }
      const isSuccess = await store.dispatch(
        `companies/${actions.CompaniesAction.ADD_NEW_COMPANY}`,
        docOperation,
      )
      isLoading.value = false
      if (isSuccess) {
        // TODO: Address and bankAccounts not getting reset.
        Object.assign(formState, getInitialState())
      }
    }

    return {
      formRef,
      formState,
      rules,
      labelCol,
      wrapperCol,
      activeTabKey,
      isBankAccountModalVisible,
      handleAddBankAccount,
      handleRemoveBankAccount,
      isLoading,
      handleFinish,
    }
  },
})
</script>

<style scoped></style>
