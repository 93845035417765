
import { ICompanyBankAccount } from '@/types/interfaces/ICompanyBankAccount'
import { defineComponent, PropType, ref } from 'vue'
const bankAccountColumns = [
  {
    title: 'A/C Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'A/C No.',
    dataIndex: 'acno',
    key: 'acno',
  },
  {
    title: 'IFSC',
    dataIndex: 'ifsc',
    key: 'ifsc',
  },
  {
    title: 'Bank Name',
    dataIndex: 'bankName',
    key: 'bankName',
  },
  {
    title: 'Branch Name',
    dataIndex: 'branchName',
    key: 'branchName',
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'BankAccountAddTable',
  props: {
    dataSourceProp: {
      type: Object as PropType<ICompanyBankAccount[]>,
      required: true,
    },
  },
  emits: ['onRemoveBankAccount'],
  setup(props, { emit }) {
    const dataSource = ref(props.dataSourceProp)
    const handleRemove = (index: number) => {
      emit('onRemoveBankAccount', index)
    }
    return {
      bankAccountColumns,
      dataSource,
      handleRemove,
    }
  },
})
