
import { ICompanyBankAccount } from '@/types/interfaces/ICompanyBankAccount'
import { defineComponent, reactive, ref, toRaw } from 'vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 6 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 14 },
}

const initialState: ICompanyBankAccount = {
  name: null,
  acno: null,
  ifsc: null,
  bankName: null,
  branchName: null,
  payee: null,
  remarks: null,
}

export default defineComponent({
  name: 'BankAccountInputForm',
  emits: ['onAddBankAccount'],
  setup(props, { emit }) {
    const formRef = ref()
    const formState = reactive({ ...initialState })

    const rules = reactive({
      name: [{ required: true, message: 'Please enter A/C Name', trigger: 'blur', type: 'string' }],
      payee: [{ required: true, message: 'Please input Payee.', trigger: 'blur', type: 'string' }],
      acno: [{ required: true, message: 'Please input A/C No.', trigger: 'blur', type: 'string' }],
      ifsc: [{ required: true, message: 'Please enter IFSC', trigger: 'blur', type: 'string' }],
      bankName: [
        { required: true, message: 'Please enter bank name.', trigger: 'blur', type: 'string' },
      ],
      branchName: [
        { required: true, message: 'Please enter branch name', trigger: 'blur', type: 'string' },
      ],
    })

    const handleBankAccountSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          // NOTE: using just toRaw(obj) creates a reference which changes on
          // resetFields so using it as { ...toRaw(formState) }
          const rawForm = { ...toRaw(formState) }
          emit('onAddBankAccount', rawForm)
          formRef.value.resetFields()
        })
        .catch(() => {
          formRef.value.scrollToField()
        })
    }

    return {
      formState,
      formRef,
      rules,
      labelCol,
      wrapperCol,
      handleBankAccountSubmit,
    }
  },
})
