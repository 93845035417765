
import { defineComponent, reactive, ref, toRaw } from 'vue'
import InputFormAddress from '@/components/InputForms/InputFormAddress.vue'
import BankAccountInputForm from '@/components/InputForms/BankAccountInputForm.vue'
import ModalSlot from '@/components/General/ModalSlot.vue'
import { ICompanyBankAccount } from '@/types/interfaces/ICompanyBankAccount'
import { CompanyFormData } from '@/types/appcontracts/CompanyFormData'
import { IAddressFormat } from '@/types/interfaces/IAddressFormat'
import BankAccountAddTable from './BankAccountsAddTable.vue'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { useStore } from 'vuex'
import * as actions from '../../../../store/actions.type'
import { isGstValid } from '@/services/helpers'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 3 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 7 },
}

const initialAddress: IAddressFormat = {
  country: 'India',
  attention: null,
  city: null,
  state: null,
  address: null,
  pincode: null,
}

const getInitialState = () => {
  const initialState: CompanyFormData = {
    companyName: null,
    branchName: null,
    gstin: null,
    invoicePrefix: null,
    branchAddress: { ...initialAddress },
    bankAccounts: [],
    email: null,
    phone: null,
    pan: null,
    photoUrl: null,
  }

  return initialState
}

export default defineComponent({
  components: {
    InputFormAddress,
    BankAccountInputForm,
    ModalSlot,
    BankAccountAddTable,
  },
  setup() {
    const store = useStore()
    const formRef = ref()
    const formState = reactive(getInitialState())

    const validateGstin = async (rule: unknown, value: string) => {
      return isGstValid(value) ? Promise.resolve(true) : Promise.reject('Please enter valid GSTIN')
    }

    // TODO: Add validator for gstin ?
    const rules = {
      companyName: [
        { required: true, message: 'Please enter Company Name', trigger: 'blur', type: 'string' },
      ],
      branchName: [
        { required: true, message: 'Please enter Branch name', trigger: 'blur', type: 'string' },
      ],
      gstin: [
        { required: true, message: 'Please enter GSTIN', trigger: 'blur', type: 'string' },
        { validator: validateGstin, trigger: 'blur' },
      ],
      invoicePrefix: [
        { required: true, message: 'Please enter Invoice Prefix', trigger: 'blur', type: 'string' },
      ],
    }
    const isLoading = ref<boolean>(false)
    const activeTabKey = ref('1')
    const isBankAccountModalVisible = ref<boolean>(false)

    const handleAddBankAccount = (bankAccount: ICompanyBankAccount | null) => {
      if (bankAccount) {
        formState.bankAccounts.push(bankAccount)
      }
      isBankAccountModalVisible.value = false
    }

    const handleRemoveBankAccount = (idx: number) => {
      formState.bankAccounts.splice(idx, 1)
    }

    const handleFinish = async () => {
      isLoading.value = true
      const docOperation: DocumentOperation<CompanyFormData> = {
        action: DocumentActions.CREATE,
        id: null,
        payload: toRaw(formState),
        audit: null,
      }
      const isSuccess = await store.dispatch(
        `companies/${actions.CompaniesAction.ADD_NEW_COMPANY}`,
        docOperation,
      )
      isLoading.value = false
      if (isSuccess) {
        // TODO: Address and bankAccounts not getting reset.
        Object.assign(formState, getInitialState())
      }
    }

    return {
      formRef,
      formState,
      rules,
      labelCol,
      wrapperCol,
      activeTabKey,
      isBankAccountModalVisible,
      handleAddBankAccount,
      handleRemoveBankAccount,
      isLoading,
      handleFinish,
    }
  },
})
